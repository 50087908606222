<template>
  <v-container>
    <v-row>
      <v-col
        v-for="(item, ind) in imageBlocksSrc[`block${idBlock}${index}`]"
        :key="ind"
        cols="12"
        md="4"
      >
        <v-card-actions>
          <v-hover v-slot:default="{ hover }">
            <v-card :elevation="hover ? 3 : 20" class="mx-auto">
              <v-img
                :id="ind"
                ref="images"
                max-height="150"
                :src="item"
                contain
                :style="
                  check === ind && !imageBlocksSrc[`hidden${idBlock}${index}`]
                    ? 'opacity: 0.2'
                    : 'opacity: 1'
                "
                @click="exchange(item, ind)"
              >
                <v-expand-transition>
                  <div
                    v-if="check !== ind && imageBlocksSrc[`hidden${idBlock}${index}`]"
                    class="d-flex transition-fast-in-fast-out orange darken-2 v-card--reveal text-h4 white--text"
                    style="height: 100%;"
                  >
                    Выбрать
                  </div>
                </v-expand-transition>
              </v-img>
            </v-card>
          </v-hover>
        </v-card-actions>
      </v-col>
    </v-row>
    <slot>
      <v-divider />
      <v-row>
        <v-col cols="12" md="3">
          <v-card-actions>
            <v-card class="mx-auto">
              <v-img max-height="150" :src="answerImg" contain />
            </v-card>
          </v-card-actions>
        </v-col>
      </v-row>
    </slot>
  </v-container>
</template>

<script>
export default {
  name: 'DialogQuestionBlockImage',
  props: {
    titleStudios: {
      type: String,
      required: true
    },
    idBlock: {
      type: String,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    answerBlockImg: {
      type: String,
      required: true
    }
  },
  data: () => ({
    photoPositionUrl:
      'https://firebasestorage.googleapis.com/v0/b/first-project-6daea.appspot.com/o/admin%2FemptyPlace.png?alt=media&token=920bf2cc-8e37-4628-a36e-d5cf47dda7f1',
    answerImg: '',
    check: ''
  }),
  computed: {
    imageBlocksSrc() {
      return this.$store.getters.imageBlocksSrc
    }
  },
  created() {
    if (this.answerBlockImg) {
      this.answerImg = this.answerBlockImg
    } else {
      this.answerImg = this.photoPositionUrl
      this.$emit('changeAnswerImag', this.photoPositionUrl)
      this.$emit('changeValidImageBlocksSrc')
    }
  },
  methods: {
    exchange(item, index) {
      this.answerImg = item
      this.check = index
      this.$emit('changeAnswerImag', item)
    }
  }
}
</script>

<style scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 1;
  position: absolute;
  width: 100%;
}
</style>
